



export default function Spinner() {
    return (
        <svg id="spinner" width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g id="em1">
                <path d="M43.6148 43.964L38.9708 60.74H35.6228L31.2668 44.432C31.2908 47.36 31.3388 50.312 31.4108 53.288C31.5068 56.264 31.5788 59.168 31.6268 62H25.9388C25.9868 59.528 26.0228 57.08 26.0468 54.656C26.0708 52.208 26.0828 49.748 26.0828 47.276C26.0828 44.804 26.0708 42.356 26.0468 39.932C26.0228 37.484 25.9868 35.024 25.9388 32.552H33.6428L37.4588 49.436L41.7428 32.552H49.0148C48.9428 37.448 48.9068 42.356 48.9068 47.276C48.9068 52.196 48.9428 57.104 49.0148 62H43.2548L43.6148 43.964Z" />
            </g>
            <g id="em2">
                <path d="M43.6148 43.964L38.9708 60.74H35.6228L31.2668 44.432C31.2908 47.36 31.3388 50.312 31.4108 53.288C31.5068 56.264 31.5788 59.168 31.6268 62H25.9388C25.9868 59.528 26.0228 57.08 26.0468 54.656C26.0708 52.208 26.0828 49.748 26.0828 47.276C26.0828 44.804 26.0708 42.356 26.0468 39.932C26.0228 37.484 25.9868 35.024 25.9388 32.552H33.6428L37.4588 49.436L41.7428 32.552H49.0148C48.9428 37.448 48.9068 42.356 48.9068 47.276C48.9068 52.196 48.9428 57.104 49.0148 62H43.2548L43.6148 43.964Z" />
            </g>
            <g id="em3">
                <path d="M43.6148 43.964L38.9708 60.74H35.6228L31.2668 44.432C31.2908 47.36 31.3388 50.312 31.4108 53.288C31.5068 56.264 31.5788 59.168 31.6268 62H25.9388C25.9868 59.528 26.0228 57.08 26.0468 54.656C26.0708 52.208 26.0828 49.748 26.0828 47.276C26.0828 44.804 26.0708 42.356 26.0468 39.932C26.0228 37.484 25.9868 35.024 25.9388 32.552H33.6428L37.4588 49.436L41.7428 32.552H49.0148C48.9428 37.448 48.9068 42.356 48.9068 47.276C48.9068 52.196 48.9428 57.104 49.0148 62H43.2548L43.6148 43.964Z" />
            </g>
        </svg>
    )
}